<template>
    <div class="factory">
      <nav-header :navs="5" :navsitems="17"/>
      <div class="contents">
        <div class="in-banner">
          <img
            src="http://www.jiukaist.com/images/banner/10.png"
            width="100%"
            height="auto"
          />
        </div>
  
        <transition>
          <div class="pageBox waps">
            <div class="pagetits">医疗教育</div>
            <div class="gsjs clearfix animation srcospcur">
              <div class="Factorys-centers">
                <div
                  class="Factorys-items"
                  v-for="(val, index) in Factorys"
                  :key="index"
                >
                  <el-image
                    :src="val.image"
                    style="width: 100%; height: auto"
                    :preview-src-list="[val.image]"
                  />
                  <div class="text">{{ val.title }}</div>
                </div>
              </div>
            </div>
           <div class="el-pages">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total,prev,pager,next"
              :total="total"
            >
            </el-pagination>
           </div>
          </div>
        </transition>
      </div>
      <nav-footer />
    </div>
  </template>
   <script>
  import NavHeader from "@/components/NavHeader.vue";
  import NavFooter from "@/components/NavFooter.vue";
  export default {
    name: "about",
    data() {
      return {
        Factorys: [],
        currentPage: 1, // 当前页码
        pageSize: 12, // 每页显示的行数
        total: 0,
      };
    },
    components: {
      NavHeader,
      NavFooter,
    },
    mounted() {},
    created() {
      let self = this;
      document.documentElement.scrollTop='0px'
      self.getFactorys();
    },
    methods: {
      handleCurrentChange(val) {
        this.currentPage = val
        let self = this;
        this.$api.mall
          .getExample({
            limit: 12,
            type:'29',
            page: this.currentPage,
          })
          .then((ress) => {
            let res = ress.data.data.data;
            self.Factorys = res;
            document.documentElement.scrollTop='0px'
            this.total = ress.data.data.total;
          });
      },
      getFactorys() {
        let self = this;
        this.$api.mall
          .getExample({
            limit: 12,
            type:'29',
            page: 1,
          })
          .then((ress) => {
            let res = ress.data.data.data;
            self.Factorys = res;
            this.total =  ress.data.data.total;
          });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .factory {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    .v-enter {
      opacity: 0;
      transform: translateY(150px);
    }
    .v-enter-active {
      transition: all 1s ease;
    }
    .contents {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
      padding-top: 75px;
      .el-pages{
          width: 100%;
          margin: 20px auto;
          overflow: hidden;
          text-align: center;
      }
      .waps {
        width: 90%;
        max-width: 1200px;
        overflow: hidden;
        margin: 0 auto;
      }
      .pageBox {
        padding: 4% 0 2%;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        .Factorys-centers {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          box-sizing: border-box;
          padding: 8px;
          .Factorys-items {
            float: left;
            display: block;
            width: 24%;
            position: relative;
            height: 220px;
            margin-left: 1%;
            margin-top: 12px;
            box-sizing: border-box;
            .text {
              position: absolute;
              bottom: 0px;
              width: 100%;
              left: 0px;
              height: 38px;
              color: #fff;
              text-align: center;
              background-color: rgba(0, 0, 0, 0.5);
              line-height: 38px;
            }
          }
        }
        .gsjs {
          transform: translate(0, 0px);
          opacity: 1;
          transition: 1.3s;
          -webkit-transition: 1.3s;
          .imgs {
            float: left;
            width: 420px;
            overflow: hidden;
          }
          .txts {
            float: right;
            width: calc(100% - 480px);
            line-height: 2em;
            font-family: 微软雅黑;
            font-size: 16px;
            color: #000000;
          }
          .txt {
            float: right;
            width: calc(100% - 480px);
            line-height: 1.5em;
          }
        }
        .pagetits {
          font-size: 26px;
          text-align: center;
          font-weight: bold;
          line-height: 1.2em;
          padding-bottom: 3%;
          color: #333333;
        }
  
        .pagetit {
          font-size: 26px;
          text-align: center;
          font-weight: bold;
          line-height: 1.2em;
          padding-bottom: 3.5%;
          color: #333333;
        }
      }
      .in-banner {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
      }
    }
  }
  </style>