<template>
  <div class="index">
    <nav-header :navs="1" />
    <div class="content">
      <div class="swiper-centers">
        <swiper class="swiper-container" :options="swiperOption">
          <swiper-slide v-for="(item, index) in slideList" :key="index">
            <img :src="'http://www.jiukaist.com/'+item.image" alt="" width="100%" height="auto" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>

      <div class="product-center">
        <div class="tit">
          <div class="line"></div>
          <b>产品中心</b>
        </div>

        <swiper ref="swiper" :options="options">
          <swiper-slide v-for="(item, index) in slideLists" :key="index">
            <div class="slide-content" @click.stop.prevent="goProPage(item.id)">
              <img :src="item.image" alt="" width="60px" height="auto" />
              <div class="font">{{ item.text }}</div>
            </div>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>

      <div class="i-plan">
        <div class="layui-row layui-col-space15" >
          <div class="layui-col-xs3" style="cursor: pointer;" @click="goPages('/cm_city')">
            <div class="plan_bg indicate">
              <div class="plot">解决方案</div>
              <img
                src="http://www.jiukaist.com/images/icon/plan.png"
                alt="解决方案"
              />
              <p>成为业界优越的电能质量系统解决方案提供商</p>
            </div>
          </div>
          <div class="layui-col-xs3" @click="goPages('/cm_city')">
            <div class="plan_bg nph">
              <img
                src="http://www.jiukaist.com/picture/indexIMG/newog.png"
                alt="智慧城市"
              />
              <div class="plan_title">智慧城市</div>
            </div>
          </div>
          <div class="layui-col-xs3" @click="goPages('/cm_agriculture')">
            <div class="plan_bg nph">
              <img
                src="http://www.jiukaist.com/picture/indexIMG/breed.png"
                alt="智慧养殖业"
              />
              <div class="plan_title">智慧养殖业</div>
            </div>
          </div>
          <div class="layui-col-xs3" @click="goPages('/cm_industry')">
            <div class="plan_bg nph">
              <img
                src="http://www.jiukaist.com/picture/indexIMG/industry.png"
                alt="智慧工业"
              />
              <div class="plan_title">智慧工业</div>
            </div>
          </div>
        </div>
      </div>

      <div class="i-intro">
        <div class="w980">
          <div class="video">
            <div
              style="
                margin: 40px 15px 0px 30px;
                height: 216px;
                overflow: hidden;
              "
            >
              <img
                src="http://www.jiukaist.com/images/banner/7.jpg"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
          <div class="detail">
            <h2>台州安耐杰电力设备有限公司</h2>
            <p style="text-indent: 2rem">
              公司自成立以来，坚持以向社会提供清洁能源和绿色电力为己任，专注于电能质量与无功补偿技术，不断提高能源利用率，追求在无功补偿和电能质量领域的领头地位，致力于不断创造先进技术和产品，并建立了完善的技术研发、生产制造、市场销售及工程服务的整套质量管理体系。
            </p>
            <p>
              <br />
            </p>
            <a @click="goPages('about')">查看更多&gt;&gt;</a>
          </div>
          <div class="clear"></div>
        </div>
      </div>

      <div class="i-list">
        <div class="w980">
          <div class="i-news">
            <div class="i-list-tit">
              <h2 class="fl">新闻中心</h2>
              <span class="fl">COMPANY NEWS</span><a class="fr" @click.stop.prevent="goPages('/Companynew')">更多+</a>
            </div>
            <div class="news-single">
              <a @click.stop.prevent="goPages('/detail/107')"
                ><img
                  src="https://omo-oss-image.thefastimg.com/portal-saas/new2022051809265841846/cms/image/4ba22ee2-e390-49e9-a744-f037ce17c133.jpg_290xaf.jpg"
                  width="278"
                  height="193"
                  alt="“春暖花开，春燕归巢”安耐杰第一节度员工主题生日会"
                />
                <p class="layui-elip int">
                  “春暖花开，春燕归巢”安耐杰第一节度员工主题生日会
                </p></a
              >
            </div>
            <div class="list fr">
              <ul class="company-news">
                <li
                  title=" 3.8致敬每一个特别的“她”"
                  path="news"
                  id="105"
                  type="19"
                  @click.stop.prevent="goPages('/detail/105')"
                >
                  <span class="fl">01</span>
                  <div class="fr">
                    <p class="layui-elip new-titless">
                      3.8致敬每一个特别的“她”
                    </p>
                    <p>2023-07-22</p>
                  </div>
                </li>
                <li
                  title=" 2023安耐杰部门年终总结大会"
                  path="news"
                  id="103"
                  type="19"
                  @click.stop.prevent="goPages('/detail/103')"
                >
                  <span class="fl">02</span>
                  <div class="fr">
                    <p class="layui-elip new-titless">
                      2023安耐杰部门年终总结大会
                    </p>
                    <p>2023-07-22</p>
                  </div>
                </li>
                <li
                  title="椒江区政协主席王跃军一行莅临我公司调研"
                  path="news"
                  id="101"
                  type="19"
                  @click.stop.prevent="goPages('/detail/101')"
                >
                  <span class="fl">03</span>
                  <div class="fr">
                    <p class="layui-elip new-titless">
                      椒江区政协主席王跃军一行莅临我公司调研
                    </p>
                    <p>2023-07-22</p>
                  </div>
                </li>
                <li
                  title=" 椒江区委书记林强一行莅临我公司考察调研"
                  path="news"
                  id="99"
                  type="19"
                  @click.stop.prevent="goPages('/detail/99')"
                >
                  <span class="fl">04</span>
                  <div class="fr">
                    <p class="layui-elip new-titless">
                      椒江区委书记林强一行莅临我公司考察调研
                    </p>
                    <p>2023-07-22</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="clear"></div>
          </div>
          <!--<div class="i-news i-honor fr">-->
          <!--	<div class="i-list-tit">-->
          <!--		<h2 class="fl">行业资讯</h2>-->
          <!--		<span class="fl">INDUSTRY NEWS</span><a href="/index/news.html?type=21" class="fr">更多+</a>-->
          <!--	</div>-->
          <!--	<div class="list fr">-->
          <!--		<ul class="hy-news">-->

          <!--		</ul>-->
          <!--	</div>-->
          <!--	<div class="clear">-->
          <!--	</div>-->
          <!--</div>-->
          <div class="clear"></div>
        </div>
      </div>

      <div class="i-tips">
        <div class="w980">
          <ul>
            <li class="filet line">
              <img src="http://www.jiukaist.com//images/ogtruck.png" alt="" />
              <p class="fz20">全国包邮</p>
            </li>
            <li class="filet line">
              <img src="http://www.jiukaist.com//images/ogchange.png" alt="" />
              <p class="fz20">365天包换</p>
            </li>
            <li class="filet line">
              <img src="http://www.jiukaist.com//images/ogtool.png" alt="" />
              <p class="fz20">三年保修</p>
            </li>
          </ul>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <nav-footer />
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
import "swiper/css/swiper.css";
import ApiCon from "../util/Api/index";
import "swiper/css/swiper.css";
export default {
  name: "index",
  data() {
    return {
      options: {
        pagination: {
          el: ".case-swiper-pagination",
        },
        loop: true,
        loopedSlides: 5,
        slidesPerView: 5,
        speed: 1000,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loopAdditionalSlides: 1,
        on: {
          slideChangeTransitionEnd: function () {
            console.log(this.activeIndex);
          },
        },
      },
      swiperOption: {
        autoplay: true,
        loop: true,
        speed: 4000,
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      slideLists: [
        {
          id: "45",
          image: "http://www.jiukaist.com/images/icon/ele_icon7.png",
          text: "三相不平衡调节装置",
        },
        {
          id: "33",
          image: "http://www.jiukaist.com/images/icon/ele_icon1.png",
          text: "智能集成电力电容补偿装置",
        },
        {
          id: "35",
          image: "http://www.jiukaist.com/images/icon/ele_icon2.png",
          text: "智能抑谐式集成电力电容补偿装置",
        },
        {
          id: "43",
          image: "http://www.jiukaist.com/images/icon/ele_icon6.png",
          text: "无功补偿散件",
        },
        {
          id: "37",
          image: "http://www.jiukaist.com/images/icon/ele_icon3.png",
          text: "工业型智能抑谐式集成电力电容补偿装置",
        },
        {
          id: "49",
          image: "http://www.jiukaist.com/images/icon/ele_icon9.png",
          text: "多功能电力仪表",
        },
        {
          id: "51",
          image: "http://www.jiukaist.com/images/icon/ele_icon10.png",
          text: "应急电源快速连接装置",
        },
        {
          id: "41",
          image: "http://www.jiukaist.com/images/icon/ele_icon5.png",
          text: "电能质量治理装置",
        },
        {
          id: "47",
          image: "http://www.jiukaist.com/images/icon/ele_icon1.png",
          text: "高压无功自动补偿装置",
        },
        {
          id: "43",
          image: "http://www.jiukaist.com/images/icon/ele_icon6.png",
          text: "无功补偿散件",
        },
        {
          id: "39",
          image: "http://www.jiukaist.com/images/icon/ele_icon4.png",
          text: "综合测控显示装置",
        },
        //无功补偿散件
        //多功能电力仪表
      ],
      slideList: [
        {
          id: "43",
          image:
            "https://omo-oss-image.thefastimg.com/portal-saas/new2022051809265841846/cms/image/352b0aad-8b4a-4a51-a566-305edc9354cc.jpg",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    NavHeader,
    NavFooter,
  },
  mounted() {
    this.ApiCon = new ApiCon(this.$store);
    this.ApiConUrl = this.ApiCon.getApiUrl();
  },
  created() {
    this.ApiCon = new ApiCon(this.$store);
    //this.$layer.msg('你好')
    this.ApiConUrl = this.ApiCon.getApiUrl();
    this.baseUrl = this.ApiConUrl;
    let self = this;
    self.getBannerLists();
  },
  methods: {
    goProPage(val){
      let self = this
      if(val==33){
        self.$router.push('/prod_type33');
      }else if(val==35){
        self.$router.push('/prod_type35');
      }else if(val==37){
        self.$router.push('/prod_type37');
      }else if(val==39){
        self.$router.push('/prod_type39');
      }else if(val==41){
        self.$router.push('/prod_type41');
      }else if(val==43){ 
        self.$router.push('/prod_type43');
      }else if(val==45){
        self.$router.push('/prod_type45');
      }else if(val==47){
        self.$router.push('/prod_type47');
      }else if(val==49){
        self.$router.push('/prod_type49');
      }else if(val==51){
        self.$router.push('/prod_type51');
      }
    },
    goPages(val) {
      let self = this;
      this.$router.push(val);
    },
    getBannerLists() {
      let self = this;
      this.$api.mall
        .getBannerList({
          limit: 14,
          page: 1,
        })
        .then((ress) => {
          let res = ress.data.data.data;
          self.slideList = res;
        });
    },
  },
};
</script>
<style lang='scss' scoped>
.index {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
  .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
      content: '' !important;
  }
  .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: '' !important;
}
  .swiper-button-next{
    background: url(../../public/imgs/right-c.png) no-repeat center;
    opacity: 0.6;
    width:42px;
    height: 42px;
    background-size: 42px;
  }
  .swiper-button-prev{
    background: url(../../public/imgs/left-c.png) no-repeat center;
    width: 42px;
    opacity: 0.6;
    height: 42px;
    background-size: 42px;
  }
  .content {
    width: 100%;
    overflow: hidden;
    padding-top: 75px;
    .i-list {
      padding: 35px 0 40px;
      background: #f5f8fc;
      .w980 {
        position: relative;
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        .i-news {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;

          .list {
            width: 750px;
            li {
              width: 750px;

              list-style: none;
              height: 42px;
              padding: 5px 0 15px;
              border-bottom: 1px solid #dadada;
              margin-bottom: 20px;
              cursor: pointer;
              .new-titless {
                width: calc(100% - 85px);
                color: #333;
              }
              .layui-elip {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              .fr {
                width: 670px;
                padding-left: 14px;
                border-left: 1px solid #dadada;
                color: #888;
                font-size: 16px;
                display: flex;
                line-height: 42px;
              }
              span {
                display: inline-block;
                width: 50px;
                font: bold 40px/42px Arial;
                color: #c4c4c4;
                transition: color 500ms ease-in 0ms;
              }
            }
          }
          .fr {
            float: right;
            display: inline;
          }
          .news-single {
            float: left;
            width: 400px;
            height: 310px;
            margin-top: 2px;
            cursor: pointer;
            position: relative;
            .int {
              width: calc(100% - 20px);
              padding: 15px 10px;
              color: #fff;
              font-size: 16px;
              position: absolute;
              bottom: 0;
              background: #18873b;
            }
          }
          .i-list-tit {
            height: 80px;
            line-height: 70px;
            a {
              display: inline-block;
              color: #18873b;
              cursor: pointer;
            }
            span {
              display: inline-block;
              margin-left: 10px;
              font-size: 14px;
            }
            h2 {
              font-size: 32px;
              text-transform: uppercase;
              color: #18873b;
            }
          }
        }
      }
    }
    .i-tips {
      width: 100%;
      overflow: hidden;
      height: 70px;
      padding: 40px 0 32px;
      .w980 {
        position: relative;
        width: 1200px;
        overflow: hidden;
        margin: 0 auto;
        ul {
          width: 1200px;
          display: flex;
          justify-content: space-around;
          li {
            font-size: 14px;
            display: flex;
            align-items: center;
            list-style: none;
            float: left;
            .fz20 {
              font-size: 20px;
              color: #1e1e1e;
              height: 40px;
              line-height: 40px;
              margin-left: 15px;
            }
            img {
              display: inline-block;
              border: none;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .i-intro {
      width: 100%;
      height: 375px;
      padding-top: 66px;
      background: url(http://www.jiukaist.com/css/../images/bg_iintro.jpg)
        no-repeat center;
      background-size: 130% 130%;
      .w980 {
        position: relative;
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        .detail {
          position: relative;
          float: right;
          width: 600px;
          height: 294px;
          padding-top: 32px;
          /* margin-right: -440px; */
          line-height: 22px;
          color: #dbe4ed;
          a {
            display: inline-block;
            position: absolute;
            left: 0;
            cursor: pointer;
            bottom: 51px;
            width: 134px;
            border: 2px solid #f0f3f7;
            font-weight: bold;
            font-size: 14px;
            line-height: 36px;
            color: #f0f3f7;
            text-align: center;
            transition: all 500ms ease-in 0ms;
          }
          h2 {
            font-size: 24px;
            line-height: 70px;
            color: #fff;
          }
        }
        .clear {
          clear: both;
        }

        .video {
          float: left;
          width: 416px;
          height: 271px;
          /* padding: 28px 21px 27px; */
          background: url(http://www.jiukaist.com/css/../images/bg_iintrovideo.png)
            no-repeat;
        }
      }
    }
    .i-plan {
      box-sizing: border-box;
      width: 1212px;
      margin: 16px auto 50px auto;
      overflow: hidden;
      padding-top: 6px;
      padding-left: 6px;

      .layui-col-space15 {
        margin: -7.5px;
      }
      .layui-col-xs3 {
        width: 25%;
        float: left;
      }
      .plot {
        color: #333;
        height: 115px;
        text-align: center;
        line-height: 150px;
        font-size: 36px;
        font-weight: bold;
      }
      .plan_title {
        position: absolute;
        color: #fff;
        background: rgb(111 111 111 / 50%);
        width: 100%;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .nph {
        cursor: pointer;
        img {
          width: 100%;
          transform: scale(1);
          transition: all 0.5s ease;
        }
        img:hover {
          transform: scale(1.2);
        }
      }
      .plan_bg {
        height: 430px;
        overflow: hidden;
        position: relative;
      }
      .indicate {
        box-shadow: 0 0 10px 0 #ddd;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          font-size: 18px;
          padding: 15px 20px;
        }
      }
      .layui-col-space15 > * {
        padding: 7.5px;
        box-sizing: border-box;
      }
    }
    .product-center {
      max-width: 1350px;
      overflow: hidden;
      margin: 16px auto;
      padding: 0px;
      .tit {
        position: relative;
        height: 140px;
        margin: 0 auto;
        text-align: center;
        b {
          position: relative;
          z-index: 2;
          padding: 0 35px;
          background: #fff;
          font-size: 36px;
          line-height: 140px;
          color: #18873b;
        }
        .line {
          position: absolute;
          left: 43.5%;
          top: 70px;
          width: 1200px;
          height: 1px;
          background: #c3c5c9;
          margin-left: -490px;
        }
      }
      .slide-content {
        width: 100%;
        overflow: hidden;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        cursor: pointer;
        img {
          width: 60px;
          height: 60px;
          padding: 10px;
          border-radius: 50%;
          box-shadow: 0 0 10px 0 #ddd;
        }
        .font {
          padding: 10px 15px 10px;
          /* background: #fff; */
          text-align: center;
          font: bold 16px/32px "微软雅黑";
          color: #333;
        }
      }
    }
    .swiper-centers {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
    }
  }
}
</style>