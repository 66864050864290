
<template>
  <div class="factory">
    <nav-header :navs="4" :navsitems="13"/>
    <div class="contents">
      <div class="in-banner">
        <div class="swiper-centers">
          <swiper class="swiper-container" :options="swiperOption">
            <swiper-slide v-for="(item, index) in slideList" :key="index">
              <img :src="item.image" alt="" width="100%" height="auto" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>

      <transition>
        <div class="pageBox waps">
          <div class="pagetits">高压无功自动补偿装置</div>
          <div class="gsjs clearfix animation srcospcur">
            <div class="Factorys-centers">
              <div
                class="Factorys-items"
                v-for="(val, index) in Factorys"
                :key="index"
                @click="goPages(val.id)"
              >
                <el-image
                  :src="val.image"
                  style="width: 100%; height: auto"
                />
                <div class="text">{{ val.title }}</div>
              </div>
            </div>
          </div>
          <div class="el-pages">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total,prev,pager,next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </transition>
    </div>
    <nav-footer />
  </div>
</template>
  <script>
import NavHeader from "@/components/NavHeader.vue";
import NavFooter from "@/components/NavFooter.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import ApiCon from "../util/Api/index";
import "swiper/css/swiper.css";
export default {
  name: "about",
  data() {
    return {
      Factorys: [],
      currentPage: 1, // 当前页码
      pageSize: 12, // 每页显示的行数
      total: 0,
      swiperOption: {
        autoplay: true,
        loop: true,
        speed: 4000,
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      slideList: [
        {
          id: "43",
          image: "http://www.jiukaist.com/images/banner/2.png",
        },
        {
          id: "43",
          image: "http://www.jiukaist.com/images/banner/1.png",
        },
      ],
    };
  },
  components: {
    NavHeader,
    NavFooter,
    Swiper,
    SwiperSlide,
  },
  mounted() {},
  created() {
    let self = this;
    document.documentElement.scrollTop = "0px";
    self.getFactorys();
  },
  methods: {
    goPages(id) {
      this.$router.push("/prod_detail/" + id);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let self = this;
      this.$api.mall
        .getProductList({
          limit: 12,
          type: "47",
          page: this.currentPage,
        })
        .then((ress) => {
          let res = ress.data.data.data;
          self.Factorys = res;
          document.documentElement.scrollTop = "0px";
          this.total = ress.data.data.total;
        });
    },
    getFactorys() {
      let self = this;
      this.$api.mall
        .getProductList({
          limit: 12,
          type: "47",
          page: 1,
        })
        .then((ress) => {
          let res = ress.data.data.data;
          self.Factorys = res;
          this.total = ress.data.data.total;
        });
    },
  },
};
</script>
    <style lang="scss" scoped>
.factory {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
  .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
      content: '' !important;
  }
  .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: '' !important;
}
  .swiper-button-next{
    background: url(../../public/imgs/right-c.png) no-repeat center;
    opacity: 0.7;
    width: 36px;
    height: 36px;
    background-size: 36px;
  }
  .swiper-button-prev{
    background: url(../../public/imgs/left-c.png) no-repeat center;
    width: 36px;
    opacity: 0.7;
    height: 36px;
    background-size: 36px;
  }
  .v-enter {
    opacity: 0;
    transform: translateY(150px);
  }
  .v-enter-active {
    transition: all 1s ease;
  }
  .contents {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding-top: 75px;
    .el-pages {
      width: 100%;
      margin: 20px auto;
      overflow: hidden;
      text-align: center;
    }
    .waps {
      width: 90%;
      max-width: 1200px;
      overflow: hidden;
      margin: 0 auto;
    }
    .pageBox {
      padding: 4% 0 2%;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      .Factorys-centers {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        box-sizing: border-box;
        padding: 8px;
        .Factorys-items {
          float: left;
          cursor: pointer;
          display: block;
          width: 24%;
          position: relative;
          height: 228px;
          margin-left: 1%;
          margin-top: 12px;
          box-sizing: border-box;
          .text {
            position: absolute;
            bottom: 0px;
            width: 100%;
            left: 0px;
            height: 38px;
            color: #fff;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.5);
            line-height: 38px;
          }
        }
      }
      .gsjs {
        transform: translate(0, 0px);
        opacity: 1;
        transition: 1.3s;
        -webkit-transition: 1.3s;
        .imgs {
          float: left;
          width: 420px;
          overflow: hidden;
        }
        .txts {
          float: right;
          width: calc(100% - 480px);
          line-height: 2em;
          font-family: 微软雅黑;
          font-size: 16px;
          color: #000000;
        }
        .txt {
          float: right;
          width: calc(100% - 480px);
          line-height: 1.5em;
        }
      }
      .pagetits {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3%;
        color: #333333;
      }

      .pagetit {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3.5%;
        color: #333333;
      }
    }
    .in-banner {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
    }
  }
}
</style>