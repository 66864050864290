import { render, staticRenderFns } from "./prod_type45.vue?vue&type=template&id=a2e7564e&scoped=true&"
import script from "./prod_type45.vue?vue&type=script&lang=js&"
export * from "./prod_type45.vue?vue&type=script&lang=js&"
import style0 from "./prod_type45.vue?vue&type=style&index=0&id=a2e7564e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2e7564e",
  null
  
)

export default component.exports