<template>
  <div class="factorydetail">
    <nav-header :navs="6" :navsitems="a"/>
    <div class="contents">
      <div class="in-banner">
        <img
          src="http://www.jiukaist.com/images/banner/8.png"
          width="100%"
          height="auto"
        />
      </div>

        <div class="pageBox waps">
          <div class="pagetits">{{Factorys.title}}</div>
          <div class="gsjs clearfix animation srcospcur">
            <div v-html="Factorys.maincontent"></div>
          </div>
          <div class="xqpage">
            <div class="res" @click="backs()">返回</div>
          </div>
        </div>
    </div>
    <nav-footer />
  </div>
</template>
   <script>
import NavHeader from "@/components/NavHeader.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "about",
  data() {
    return {
      a:'',
      Factorys: "",
      id: this.$route.params.id,
      currentPage: 1, // 当前页码
      pageSize: 12, // 每页显示的行数
      total: 0,
    };
  },
  components: {
    NavHeader,
    NavFooter,
  },
  mounted() {},
  created() {
    let self = this;
    document.documentElement.scrollTop = "0px";
    self.init();
  },
  methods: {
    backs(){
       history.go(-1)
    },
    init() {
      let self = this;
      this.$api.mall
        .getNewdetail({
          id: self.id,
        })
        .then((ress) => {
          let res = ress.data.data;
          console.log(res, "Ssbbb");
          self.Factorys = res;
        });
    },
  },
};
</script>
  <style lang="scss" scoped>
.factorydetail{
  width: 100%;
  overflow:hidden;
  margin: 0px auto;
  .v-enter {
    opacity: 0;
    transform: translateY(150px);
  }
  .v-enter-active {
    transition: all 1s ease;
  }
  .contents {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding-top: 75px;
    .xqpage {
      width: 100%;
      margin-top: 30px;
      overflow: hidden;
      border-top: 1px dashed #dcdcdc;
      .res {
        display: block;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 80px;
        line-height: 30px;
        border-bottom: 1px solid #72a3ce;
        background: #005bac;
        color: #fff;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
      }
    }
    .el-pages {
      width: 100%;
      margin: 20px auto;
      overflow: hidden;
      text-align: center;
    }
    .waps {
      width: 90%;
      max-width: 1200px;
      overflow: hidden;
      margin: 0 auto;
    }
    .pageBox {
      padding: 4% 0 2%;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      .gsjs {
        transform: translate(0, 0px);
        width: 680px;
        overflow: hidden;
        margin: 0px auto;
        
        opacity: 1;
        transition: 1.3s;
        -webkit-transition: 1.3s;
        .imgs {
          float: left;
          width: 420px;
          overflow: hidden;
        }
        .txts {
          float: right;
          width: calc(100% - 480px);
          line-height: 2em;
          font-family: 微软雅黑;
          font-size: 16px;
          color: #000000;
        }
        .txt {
          float: right;
          width: calc(100% - 480px);
          line-height: 1.5em;
        }
      }
      .pagetits {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3%;
        color: #333333;
      }

      .pagetit {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3.5%;
        color: #333333;
      }
    }
    .in-banner {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
    }
  }
}
</style>