<template>
  <div class="about">
    <nav-header :navs="7" :navsitems="21" />
    <div class="contents">
      <div class="in-banner">
        <img
          src="http://www.jiukaist.com/images/banner/11.png"
          width="100%"
          height="auto"
        />
      </div>

      <transition>
        <div class="pageBox waps">
          <div class="pagetit">公司简介</div>
          <div class="gsjs clearfix animation srcospcur">
            <div class="imgs">
              <img
                src="http://www.jiukaist.com/images/banner/7.jpg"
                width="100%"
              />
            </div>
            <div class="txt">
              <div>
                <p>
                  <span style="color: #000000"
                    ><span style="font-size: 16px"
                      ><span style="line-height: 2em"
                        ><span style="font-family: 微软雅黑"
                          ><span
                            style="
                              font-variant-numeric: normal;
                              font-variant-east-asian: normal;
                              letter-spacing: 0pt;
                              font-weight: bold;
                              vertical-align: baseline;
                            "
                          ></span></span
                        >台州安耐杰电力设备有限公司于2011年注册成立，位于浙江省台州市，是一家长期致力于无功补偿技术、电能质量治理领域的研发、生产与销售的高新技术企业。公司主要产品分为：ANJ系列智能电容器、智能抑谐式电容器、电力电容器、串联电抗器、复合开关、动态投切开关等无功补偿产品。ANJ系列静止无功发生器（SVG）、有源电力滤波器（APF）、三相不平衡矫正（SPC）等电能质量产品。ANJ系列低电压调节、谐波治理、动静态混合补偿、高压并联自动补偿等成套补偿装置。<span
                          style="font-family: 微软雅黑"
                        ></span></span></span
                  ></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition>
        <div class="pageBox waps">
          <div class="pagetits">研发实力</div>

          <div class="gsjs clearfix animation srcospcur">
            <div class="imgs">
              <img
                src="http://www.jiukaist.com/images/banner/yanfashili.jpg"
                width="100%"
              />
            </div>
            <div class="txts">
              公司拥有一支年纪轻、素质高、肯吃苦的科研队伍，具有十余年无功补偿和电能质量产品的研发经验，并配备了国内外先进的测试仪器、设备。我们不但具备产品自主研发能力，同时也是产品制造商，拥有完整的无功补偿、电能质量治理系列产品研发、设计与制造能力，该综合能力决定了我们深刻理解配电系统无功补偿及电能质量问题，能够快速响应用户需求，并提供优越的方案以及无可比拟的竞争优势。
            </div>
          </div>
        </div>
      </transition>

      <transition>
        <div class="pageBox waps">
          <div class="pagetits">公司荣誉</div>
          <div class="gsjs clearfix animation srcospcur">
            <div class="imgs">
              <img
                src="http://www.jiukaist.com/images/banner/rongyus.jpg"
                width="100%"
              />
            </div>
            <div class="txts">
              先后荣获:国家高新企业、国家科技型中小企业、省专精特新中小企业、省”守合同重信用“AA级企业、电力工业百强榜“电能质量十企业”、“无功补偿十强企业”、台州市企业技术中心、台州市高新技术企业研究开发中心、椒江区亩均产领跑者企业等各种荣誉；
            </div>
            <div class="txts">
              陆续通过IS09001质量管理三体系认证、两化融合管理体系认证、知识产权管理体系认证、三级安全生产标准化企业认证等;公司各项产品认证会,均已取得CCC、COC等行业认证证书；
            </div>
            <div class="txts">
              多项产品荣获:浙江制造“品”字标认证、省级工业新品鉴定、浙江制造精品、市级首台套产品等多项荣誉证书。
            </div>
          </div>
        </div>
      </transition>

      <transition>
        <div class="pageBox waps">
          <div class="pagetits">公司理念</div>
          <div class="gsjs clearfix animation srcospcur">
            <div class="imgs">
              <img
                src="https://omo-oss-image.thefastimg.com/portal-saas/new2022051809265841846/cms/image/70b97fba-b0ba-41ec-b8f4-f68ac51319e6.jpg"
                width="100%"
              />
            </div>
            <div class="txts">
              公司自成立以来，坚持以向社会提供清洁能源和绿色电力为己任，专注于电能质量与无功补偿技术，不断提高能源利用率，追求在无功补偿和电能质量领域的领头地位，致力于不断创造先进技术和产品，并建立了完善的技术研发、生产制造、市场销售及工程服务的整套质量管理体系。
            </div>
            <div class="txts">
              我们始终以用户的需求为目标，为您提供性能稳定的产品和完善的售后服务，以此回报客户信任。
            </div>
          </div>
        </div>
      </transition>
    </div>
    <nav-footer />
  </div>
</template>
<script>
import NavHeader from "@/components/NavHeader.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "about",
  data() {
    return {};
  },
  components: {
    NavHeader,
    NavFooter,
  },
  mounted() {},
  created() {
    document.documentElement.scrollTop = "0px";
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.about {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
  .v-enter {
    opacity: 0;
    transform: translateY(150px);
  }
  .v-enter-active {
    transition: all 1s ease;
  }
  .contents {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding-top: 75px;
    .waps {
      width: 90%;
      max-width: 1200px;
      overflow: hidden;
      margin: 0 auto;
    }
    .pageBox {
      padding: 4% 0 2%;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      .gsjs {
        transform: translate(0, 0px);
        opacity: 1;
        transition: 1.3s;
        -webkit-transition: 1.3s;
        .imgs {
          float: left;
          width: 420px;
          overflow: hidden;
        }
        .txts {
          float: right;
          width: calc(100% - 480px);
          line-height: 2em;
          font-family: 微软雅黑;
          font-size: 16px;
          color: #000000;
        }
        .txt {
          float: right;
          width: calc(100% - 480px);
          line-height: 1.5em;
        }
      }
      .pagetits {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3%;
        color: #333333;
      }

      .pagetit {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3.5%;
        color: #333333;
      }
    }
    .in-banner {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
    }
  }
}
</style>
