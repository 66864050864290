<template>
    <div class="factorydetail">
      <nav-header :navs="2" :navsitems="1"/>
      <div class="contents">
        <div class="in-banner" style="border-bottom:1px solid #dcdcdc">
          <img
            src="http://www.jiukaist.com/images/banner/3.png"
            width="100%"
            height="auto"
          />
        </div>
  
          <div class="pageBox waps" >
            <div class="pagetits">安耐杰云简介</div>
            <div class="gsjs clearfix animation srcospcur">

                      
          <div style="padding: 5px 10px">
            <span style="font-size: 16px"
              >&nbsp; &nbsp; &nbsp;
              安耐杰物联网平台提供安全可靠的设备连接通信能力，支持设备数据采集上云，规则引擎流转数据和云端数据下发设备端。此外，也提供方便快捷的设备管理能力，支持物模型定义，数据结构化存储，和远程调试、监控、运维。</span
            >
            <br />
            <span style="font-size: 16px"
              >&nbsp; &nbsp; &nbsp;
              智慧城市成为全球城市建设发展的未来方向，带动了与之相关的产业，及关注和更高的要求，如智能建筑能耗。通过自动化的监测平台，能源管理对能耗进行管理和使用，以实现节约运行和管理费用，促进各智能子系统资源共享，协同发展，达到提升能源使用效率。因此，物联网技术将发挥巨大作用。</span
            >
          </div>
          <div style="text-align: center">&nbsp;</div>
          <h3 style="text-align:center;margin-bottom:20px">
            <span style="color: #008000">
              <strong>
                <span style="font-size: 20px">云平台简介</span>
              </strong>
            </span>
          </h3>
          <p>
            <strong>功能：</strong>
          </p>
          <div>
            <span style="font-size: 16px">&nbsp; &nbsp; &nbsp;</span>&nbsp;
            &nbsp;<span style="font-size: 18px"
              >综合能源管理系统主要针对于分布全国不同省、地市或同地市不同区域的连锁商业、集团、百货、门店、物业、院校等分布式集团性质客户设计。</span
            >
            <span style="font-size: 16px"
              >物联网云平台通过在各个本地变电所或配电室等现场安装多功能电力计量仪表；在各用能现场可安装水表，气表等各类仪表，经网关采用无线（3G/4G）或有线的方式将采集的仪表数据上传至公司租赁的云服务器上，并将数据进行集中存储、统一管理。具有权限的用户可通过PC、PAD、手机等各类终端设备访问数据、接收告警信息，方便管理者及时知晓各个点位的用能情况，及时管控各个点位用能。</span
            >
          </div>
          <p>
            <strong>应用场所：</strong>
          </p>
          <div>
            <p style="font-size: 16px">
              &nbsp; &nbsp;
              &nbsp;（一）分布式集团办公建筑（商务办公、国家机关办公建筑等）；
            </p>
            <p style="font-size: 16px">
              &nbsp; &nbsp;
              &nbsp;（二）分布式连锁商业建筑（商场、金融机构建筑等）；
            </p>
            <p style="font-size: 16px">
              &nbsp; &nbsp;
              &nbsp;（三）分布式连锁旅游建筑（宾馆饭店、娱乐场所等）；
            </p>
            <p style="font-size: 16px">
              &nbsp; &nbsp;
              &nbsp;（四）多分支科教文卫建筑（文化、教育、科研、医疗卫生、体育建筑等）；
            </p>
            <p style="font-size: 16px">
              &nbsp; &nbsp;
              &nbsp;（五）集团通信建筑（邮电、通信、广播、电视、数据中心等）；
            </p>
            <p style="font-size: 16px">
              &nbsp; &nbsp;
              &nbsp;（六）集团交通运输建筑（机场、车站、码头建筑等）。
            </p>
          </div>

          <h3 style="text-align:center;margin-bottom:20px">
            <br />
            <span style="color: #008000">
              <strong>
                <span style="font-size: 20px">综合能源管理系统结构</span>
              </strong>
            </span>
          </h3>
          <p style="text-align: center">
            <a
              class="fluidbox fluidbox__instance-2 fluidbox--initialized fluidbox--closed fluidbox--ready"
              href="../images/platpht/1690189939175.png"
              data-fluidbox=""
              data-fluidbox-loader="true"
            >
              <div class="fluidbox__wrap" style="z-index: 1">
                <img
                  alt=""
                  src="http://www.jiukaist.com/images/platpht/1690189939175.jpg"
                  class="fluidbox__thumb"
                  style="opacity: 1;width: 100%;height:auto"
                />
                <div class="fluidbox__loader" style="z-index: 2"></div>
              </div>
            </a>
          </p>
          <p>
            <strong>综合能源管理系统功能</strong>
          </p>
          <p>
            <span style="font-size: 16px"
              >&nbsp;&nbsp;&nbsp;
              登陆成功后，默认打开能源管理云平台的“平台概括”界面；能源管理云平台主要包括“平台概况”，
              “建筑用能”，“支路用能”，“分项用能” ，“部门用能”
              ，“区域用能”，“系统设置”等七个功能模块。能源管理云平台支持亮色、暗色两种主题颜色切换，支持中英文两种语言切换。</span
            >
          </p>
          <div class="fluidbox__wrap" style="z-index: 1">
            <img
              alt=""
              src="http://www.jiukaist.com/images/platpht/1690190017263.jpg"
              class="fluidbox__thumb"
              style="opacity: 1;width: 100%;height:auto"
            />
            <div class="fluidbox__loader" style="z-index: 2"></div>
          </div>
          <div class="fluidbox__wrap" style="z-index: 1">
            <img
              alt=""
              src="http://www.jiukaist.com/images/platpht/1690190041995.jpg"
              class="fluidbox__thumb"
              style="opacity: 1;width: 100%;height:auto"
            />
            <div class="fluidbox__loader" style="z-index: 2"></div>
          </div>

            </div>
            <div class="xqpage">
              <!--
              <div class="res" @click="backs()">返回</div>-->
            </div>
          </div>
      </div>
      <nav-footer />
    </div>
  </template>
     <script>
  import NavHeader from "@/components/NavHeader.vue";
  import NavFooter from "@/components/NavFooter.vue";
  export default {
    name: "about",
    data() {
      return {
        Factorys: {
            title:'',
            maincontent:''
        },
        id: '',
        currentPage: 1, // 当前页码
        pageSize: 12, // 每页显示的行数
        total: 0,
      };
    },
    components: {
      NavHeader,
      NavFooter,
    },
    mounted() {},
    created() {
      let self = this;
      document.documentElement.scrollTop = "0px";
      //self.init();
    },
    methods: {
      backs(){
         history.go(-1)
      },
      init() {
        let self = this;
        this.$api.mall
          .getNewdetail({
            id: self.id,
          })
          .then((ress) => {
            let res = ress.data.data;
            console.log(res, "Ssbbb");
            self.Factorys = res;
          });
      },
    },
  };
  </script>
    <style lang="scss" scoped>
  .factorydetail{
    width: 100%;
    overflow:hidden;
    margin: 0px auto;
    .v-enter {
      opacity: 0;
      transform: translateY(150px);
    }
    .v-enter-active {
      transition: all 1s ease;
    }
    .contents {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
      padding-top: 75px;
      .xqpage {
        width: 100%;
        margin-top: 30px;
        overflow: hidden;
        border-top: 1px dashed #dcdcdc;
        .res {
          display: block;
          margin-top: 30px;
          margin-bottom: 20px;
          width: 80px;
          line-height: 30px;
          border-bottom: 1px solid #72a3ce;
          background: #005bac;
          color: #fff;
          cursor: pointer;
          font-size: 12px;
          text-align: center;
        }
      }
      .el-pages {
        width: 100%;
        margin: 20px auto;
        overflow: hidden;
        text-align: center;
      }
      .waps {
        width: 90%;
        max-width: 1200px;
        overflow: hidden;
        margin: 0 auto;
      }
      .pageBox {
        line-height: 32px;
        padding: 4% 0 2%;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        .gsjs {
          transform: translate(0, 0px);
          width: 680px;
          overflow: hidden;
          margin: 0px auto;
          
          opacity: 1;
          transition: 1.3s;
          -webkit-transition: 1.3s;
          .imgs {
            float: left;
            width: 420px;
            overflow: hidden;
          }
          .txts {
            float: right;
            width: calc(100% - 480px);
            line-height: 2em;
            font-family: 微软雅黑;
            font-size: 16px;
            color: #000000;
          }
          .txt {
            float: right;
            width: calc(100% - 480px);
            line-height: 1.5em;
          }
        }
        .pagetits {
          font-size: 26px;
          text-align: center;
          font-weight: bold;
          line-height: 1.2em;
          padding-bottom: 3%;
          color: #333333;
        }
  
        .pagetit {
          font-size: 26px;
          text-align: center;
          font-weight: bold;
          line-height: 1.2em;
          padding-bottom: 3.5%;
          color: #333333;
        }
      }
      .in-banner {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
      }
    }
  }
  </style>