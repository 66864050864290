<template>
  <!-- 顶部导航 -->
  <div class="header" v-on:mouseleave="handleave(1)">
    <div class="container-top">
      <div class="nav-topbar">
        <div class="logo">
          <img
            src="../../public/imgs/header_logo.png"
            width="100%"
            height="auto"
          />
        </div>
        <div class="logo-text">
          <p>专注于电能质量与无功补偿技术</p>
          <p>致力于不断创造先进技术和产品</p>
        </div>

        <div class="container">
          <div
            class="item-topbar"
            :class="{ active: a == 1, activea: activea == 1 }"
            v-on:mouseover="handleHover(1)"
            @click="goPages('/index', 1, 1)"
          >
            <span :class="{ 'active-line': a == 1 }"> 首页 </span>
          </div>
          <div
            class="item-topbar"
            :class="{ active: a == 2, activea: activea == 2 }"
            v-on:mouseover="handleHover(2)"
          >
            <span :class="{ 'active-line': a == 2 }"> 安耐杰云平台</span>
          </div>
          <div
            class="item-topbar"
            :class="{ active: a == 3, activea: activea == 3 }"
            v-on:mouseover="handleHover(3)"
          >
            <span :class="{ 'active-line': a == 3 }"> 解决方案 </span>
          </div>
          <div
            class="item-topbar"
            :class="{ active: a == 4, activea: activea == 4 }"
            v-on:mouseover="handleHover(4)"
            @click="goPages('/product_centers', '4', '')"
          >
            <span :class="{ 'active-line': a == 4 }"> 产品中心 </span>
          </div>
          <div
            class="item-topbar"
            :class="{ active: a == 5, activea: activea == 5 }"
            v-on:mouseover="handleHover(5)"
          >
            <span :class="{ 'active-line': a == 5 }"> 项目案例 </span>
          </div>
          <div
            class="item-topbar"
            :class="{ active: a == 6, activea: activea == 6 }"
            v-on:mouseover="handleHover(6)"
          >
            <span :class="{ 'active-line': a == 6 }"> 新闻中心 </span>
          </div>
          <div
            class="item-topbar"
            :class="{ active: a == 7, activea: activea == 7 }"
            v-on:mouseover="handleHover(7)"
          >
            <span :class="{ 'active-line': a == 7 }"> 关于我们 </span>
          </div>
          <div
            class="item-topbar"
            :class="{ active: a == 8, activea: activea == 8 }"
            v-on:mouseover="handleHover(8)"
            @click="goPages('/contact', 8, 8)"
          >
            <span :class="{ 'active-line': a == 8 }"> 联系我们 </span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-nav" v-show="showsNav">
      <transition>
        <div class="nav-centers" v-if="b == 2" style="padding-left: 45%">
          <div
            class="nav-items"
            :class="{ navactive: nava == 1 }"
            v-on:mouseover="handlenav(1)"
            @click="goPages('/yunplat', 1, 1)"
          >
            <span> 云简介 </span>
          </div>
          <div
            class="nav-items"
            :class="{ navactive: nava == 2 }"
            v-on:mouseover="handlenav(2)"
            @click="goPages('/yunshow', 1, 2)"
          >
            <span> 云展示 </span>
          </div>
        </div>
      </transition>

      <transition>
        <div class="nav-centers" v-if="b == 3" style="padding-left: 45%">
          <div
            class="nav-items"
            :class="{ navactive: nava == 3 }"
            v-on:mouseover="handlenav(3)"
            @click="goPages('/cm_city', 3, 3)"
          >
            <span> 智慧城市 </span>
          </div>
          <div
            class="nav-items"
            :class="{ navactive: nava == 4 }"
            v-on:mouseover="handlenav(4)"
            @click="goPages('/cm_agriculture', 3, 4)"
          >
            <span> 智慧养殖业 </span>
          </div>

          <div
            class="nav-items"
            :class="{ navactive: nava == 5 }"
            v-on:mouseover="handlenav(5)"
            @click="goPages('/cm_industry', 3, 5)"
          >
            <span> 智慧工业 </span>
          </div>
        </div>
      </transition>

      <transition>
        <div class="nav-centers" v-if="b == 5" style="padding-left: 60%">
          <div
            class="nav-items"
            :class="{ navactive: nava == 16 }"
            v-on:mouseover="handlenav(16)"
            @click="goPages('/buildings', 5, 16)"
          >
            <span> 公共建筑 </span>
          </div>
          <div
            class="nav-items"
            :class="{ navactive: nava == 17 }"
            v-on:mouseover="handlenav(17)"
            @click="goPages('/medical', 5, 17)"
          >
            <span> 医疗教育 </span>
          </div>

          <div
            class="nav-items"
            :class="{ navactive: nava == 18 }"
            v-on:mouseover="handlenav(18)"
            @click="goPages('/industry', 5, 18)"
          >
            <span> 工业制造 </span>
          </div>
        </div>
      </transition>

      <transition>
        <div class="nav-centers" v-if="b == 6" style="padding-left: 65%">
          <div
            class="nav-items"
            :class="{ navactive: nava == 19 }"
            v-on:mouseover="handlenav(19)"
            @click="goPages('/Companynew', 6, 19)"
          >
            <span> 公司资讯 </span>
          </div>
          <div
            class="nav-items"
            :class="{ navactive: nava == 20 }"
            v-on:mouseover="handlenav(20)"
            @click="goPages('/news', 6, 20)"
          >
            <span> 行业资讯 </span>
          </div>
        </div>
      </transition>

      <transition>
        <div class="nav-centers" v-if="b == 7" style="padding-left: 70%">
          <div
            class="nav-items"
            :class="{ navactive: nava == 21 }"
            v-on:mouseover="handlenav(21)"
            @click="goPages('/about', 7, 21)"
          >
            <span> 公司简介 </span>
          </div>
          <div
            class="nav-items"
            :class="{ navactive: nava == 22 }"
            v-on:mouseover="handlenav(22)"
            @click="goPages('/factory', 7, 22)"
          >
            <span> 公司环境 </span>
          </div>
          <div
            class="nav-items"
            :class="{ navactive: nava == 23 }"
            v-on:mouseover="handlenav(23)"
            @click="goPages('/honor', 7, 23)"
          >
            <span> 荣誉资质 </span>
          </div>
          <div
            class="nav-items"
            :class="{ navactive: nava == 24 }"
            v-on:mouseover="handlenav(24)"
            @click="goPages('/leader', 7, 24)"
          >
            <span> 领导关怀 </span>
          </div>
        </div>
      </transition>
      <transition>
        <div class="nav-centers" v-if="b == 4">
          <div class="nav-centers-list">
            <div class="nav-cs">
              <div class="li-list-item_1">
                <div class="li-list-item list-items-title" @click="goPagetypes('a1')">智能仪表</div>
                <div class="li-list-item list-items-title-text">智能仪表T8/T9系列</div>
                <div class="li-list-item list-items-title-text">智能仪表Z6系列</div>
                <div class="li-list-item list-items-title-text">智能导轨式仪表D9系列</div>
                <div class="li-list-item list-items-title-text">智慧采集终端</div>
              </div>

              <div class="li-list-item_2" @click="goPagetypes('a2')">
                <div class="li-list-item list-items-title">智能集成电力电容补偿装置</div>
                <div class="li-list-item list-items-title-text">F系列</div>                                                                                        
                <div class="li-list-item list-items-title-text">H系列</div>
              </div>

              <div class="li-list-item_3">
                <div class="li-list-item list-items-title"  @click="goPagetypes('a3')">
                  智能抑谐式集成电力电容补偿装置
                </div>
                <div class="li-list-item list-items-title-text">单电容电抗</div>
                <div class="li-list-item list-items-title-text">双电容电容</div>
                <div class="li-list-item list-items-title-text">智能动态抑谐式电容器</div>
              </div>

              <div class="li-list-item_4" >
                <div class="li-list-item list-items-title"  @click="goPagetypes('a4')">电能质量治理装置</div>
                <div class="li-list-item list-items-title-text">有源电力滤波器</div>
                <div class="li-list-item list-items-title-text">静止无功发生器</div>
                <div class="li-list-item list-items-title-text">电能质量成套装置</div>
              </div>

              <div class="li-list-item_5">
                <div class="li-list-item list-items-title"  @click="goPagetypes('a5')">综合测控装置</div>
                <div class="li-list-item list-items-title-text">低压无功综合测控仪</div>
              </div>

              <div class="li-list-item_6">
                <div class="li-list-item list-items-title"  @click="goPagetypes('a6')">传统式无功补偿器件</div>
                <div class="li-list-item list-items-title-text">自愈式低压电力电容器</div>
                <div class="li-list-item list-items-title-text">抑谐式无功补偿组件</div>
                <div class="li-list-item list-items-title-text">智能复合开关</div>
                <div class="li-list-item list-items-title-text">动态投切开关</div>
                <div class="li-list-item list-items-title-text">传统式无功补偿控制器</div>
              </div>

              
              <div class="li-list-item_6">
                <div class="li-list-item list-items-title"  @click="goPagetypes('a7')">物联终端</div>
                <div class="li-list-item list-items-title-text">网关采集器</div>
                <div class="li-list-item list-items-title-text">能效采集终端</div>
                <div class="li-list-item list-items-title-text">输入输出控制模块</div>
                <div class="li-list-item list-items-title-text">传感器</div>
              </div>

            </div>
          </div>

          <!--
            <div
              class="nav-items"
              :class="{ navactive: nava == 6 }"
              v-on:mouseover="handlenav(6)"
              @click="goPages('/prod_type33', 4, 6)"
            >
              <span> 智能集成电力电容补偿装置 </span>
            </div>
            <div
              class="nav-items"
              :class="{ navactive: nava == 7 }"
              v-on:mouseover="handlenav(7)"
              @click="goPages('/prod_type35', 4, 7)"
            >
              <span> 智能抑谐式集成电力电容补偿装置 </span>
            </div>
  
            <div
              class="nav-items"
              :class="{ navactive: nava == 8 }"
              v-on:mouseover="handlenav(8)"
              @click="goPages('/prod_type37', 4, 8)"
            >
              <span> 工业型智能抑谐式集成电力电容补偿装置 </span>
            </div>
  
            <div
              class="nav-items"
              :class="{ navactive: nava == 9 }"
              v-on:mouseover="handlenav(9)"
              @click="goPages('/prod_type39', 4, 9)"
            >
              <span> 综合测控显示装置 </span>
            </div>
  
            <div
              class="nav-items"
              :class="{ navactive: nava == 10 }"
              v-on:mouseover="handlenav(10)"
              @click="goPages('/prod_type41', 4, 10)"
            >
              <span> 电能质量治理装置 </span>
            </div>
  
            <div
              class="nav-items"
              :class="{ navactive: nava == 11 }"
              v-on:mouseover="handlenav(11)"
              @click="goPages('/prod_type43', 4, 11)"
            >
              <span> 无功补偿散件 </span>
            </div>
  
            <div
              class="nav-items"
              :class="{ navactive: nava == 12 }"
              v-on:mouseover="handlenav(12)"
              @click="goPages('/prod_type45', 4, 12)"
            >
              <span> 三相不平衡调节装置 </span>
            </div>
  
            <div
              class="nav-items"
              :class="{ navactive: nava == 13 }"
              v-on:mouseover="handlenav(13)"
              @click="goPages('/prod_type47', 4, 13)"
            >
              <span> 高压无功自动补偿装置 </span>
            </div>
  
            <div
              class="nav-items"
              :class="{ navactive: nava == 14 }"
              v-on:mouseover="handlenav(14)"
              @click="goPages('/prod_type49', 4, 14)"
            >
              <span> 多功能电力仪表 </span>
            </div>
  
            <div
              class="nav-items"
              :class="{ navactive: nava == 15 }"
              v-on:mouseover="handlenav(15)"
              @click="goPages('/prod_type51', 4, 15)"
            >
              <span> 应急电源快速连接装置 </span>
            </div>-->
        </div>
      </transition>
    </div>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
export default {
  name: "nav-header",
  props: ["navs", "navsitems"],
  data() {
    return {
      a: 1,
      b: "",
      activenava: "",
      nava: "",
      activea: 1,
      showsNav: false,
      phoneList: [],
    };
  },
  watch: {
    navs: {
      handler(newVal) {
        //this.b = newVal;
        this.activea = newVal;
        this.a = newVal;
        this.showsNav = true;
      },
      // 立即处理 进入页面就触发
      immediate: true,
      // 深度监听 属性的变化
      deep: true,
    },
    navsitems: {
      handler(newVal) {
        this.activenava = newVal;
        this.nava = newVal;
      },
      // 立即处理 进入页面就触发
      immediate: true,
      // 深度监听 属性的变化
      deep: true,
    },
  },
  methods: {
    handlenav(val) {
      let self = this;
      self.nava = val;
    },
    handleHover(val) {
      let self = this;
      self.a = val;
      self.b = val;
      self.showsNav = true;
    },
    handleave(val) {
      let self = this;
      if (self.activea == 1) {
        self.b = "";
        self.nava = "";
        self.showsNav = false;
      } else {
        self.b=''
        self.nava = self.activenava;
      }
      self.a = self.activea;
    },
    goPagetypes(val) {
        let self=this
        self.b=false
        self.a=false
        self.nava = false
        self.$router.push('/product_type_list/'+val);
        location.reload()
    },
    goPages(val, a, b) {
      let self = this;
      self.activea = a;
      self.a = a;
     // self.b = a;
      self.nava = b;
      this.$router.push(val);
      location.reload()
    },
  },
};
</script>
  
  <style lang="scss" scoped>
// @import "./../assets/scss/base.scss";
// @import "./../assets/scss/config.scss";
.header {
  z-index: 33;
  position: fixed;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  /**
      padding-right: 0.5rem;
    width: 36%;
    margin-bottom: 0.45rem;
    .v-enter {
      opacity: 0;
      transform: translateY(150px);
    }
    .v-enter-active {
      transition: all 0.5s ease;
    }**/
  .container-nav {
    width: 100%;
    overflow: hidden;
    background: #f5f5f5;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    .nav-centers {
      width: 1600px;
      overflow: hidden;
      margin: 0 auto;
      //#f5f5f5
      .nav-centers-list {
        width: 1213px;
        overflow: hidden;
        margin: 0px auto;
        background: #f5f5f5;
        display: block;
        height: 428px;
        padding-top:30px;
        margin-top: 0px;
        padding-bottom: 60px;
        margin-bottom: 0px;
        .nav-cs {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 74.71%;
        }
        .li-list-item {
          cursor: pointer;
        }
        .li-list-item:hover {
          color: #1d8d3b;
        }
        .li-list-item_1 {
          padding-right: 0.5rem;
          width: 36%;
          margin-bottom: 0.45rem;
          cursor: pointer;
        }
        .li-list-item_2 {
          padding-right: 0.5rem;
          width: 34%;
          margin-bottom: 0.45rem;
          cursor: pointer;
        }
        .li-list-item_3 {
          padding-right: 0;
          width: 27%;
          margin-bottom: 0.45rem;
          cursor: pointer;
        }
        .li-list-item_4 {
          padding-right: 0.5rem;
          width: 36%;
          margin-top: 1rem;
          cursor: pointer;
        }
        .li-list-item_5 {
          padding-right: 0.5rem;
          width: 36%;
          margin-top: 1rem;
          margin-bottom: 0.45rem;
          cursor: pointer;
        }
        .li-list-item_6 {
          padding-right: 0;
          width: 36%;
          padding-right: 0.5rem;
          margin-top: 1rem;
          margin-bottom: 0rem;
          cursor: pointer;
        }
        .list-items-title-text {
          display: block;
          color: #666666;
          line-height: 2;
          text-align: left;
          margin-top: 0.17rem;
          transition: 0.3s all;
          font-size: 14px;
        }
        .list-items-title {
          font-size: 16px;
          color: #333333;
          line-height: initial;
          display: block;
          text-align: left;
          transition: 0.3s all;
        }
      }
      .navactive {
        background: #fff;
        span {
          height: 30px;
          line-height: 30px;
          margin: 14px auto;
          width: 100%;
          border-bottom: 1px solid #1d8d3b;
          display: inline-block;
        }
      }
      .nav-items {
        display: block;
        float: left;
        overflow: hidden;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        line-height: 60px;
        position: relative;
        cursor: pointer;
      }
    }
  }

  .container-top {
    width: 100%;
    margin: 0 auto;
    background: #fff;
  }
  .nav-topbar {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;

    max-width: 1600px;
    padding-top: 28px;
    padding-bottom: 10px;
    .logo {
      float: left;
      width: 238px;
      overflow: hidden;
      border-right: 1px solid #dfdfdf;
    }
    .logo-text {
      float: left;
      width: 278px;
      overflow: hidden;
      p {
        font-size: 16px;
        text-align: center;
        line-height: 16px;
        width: 100%;
        overflow: hidden;
        color: #1d8d3b;
        font-weight: 700;
      }
    }

    .container {
      @include flex();
      float: right;
      width: 52%;
      overflow: hidden;
      line-height: 32px;
      .active {
        // font-weight: bold;
        color: #1d8d3b !important;
      }
      .activea {
        font-weight: bold;
        color: #1d8d3b !important;
      }
      .active-line {
        display: inline-block;
        line-height: 32px;
        color: #1d8d3b;
        border-bottom: 3px solid #d51419;
      }
      .item-topbar {
        font-size: 16px;
        display: inline-block;
        color: #333;
        cursor: pointer;
      }
    }
  }
}
</style>