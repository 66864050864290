
<template>
  <div class="product_type_lists">
    <nav-header :navs="4" :navsitems="a" />
    <div class="product_type_centers">
      <div class="g-proList-box">
        <div class="g-order_list_box">
          <div
            :class="{ c1: a1 == true, c1s: a1 == false }"
            @click="showNavType('a1')"
          >
            - 智能仪表
          </div>
          <div v-show="a1">
            <div class="c2">智能仪表T8/T9系列</div>
            <div class="c2">智能仪表Z6系列</div>
            <div class="c2">智能导轨式仪表D9系列</div>
            <div class="c2">智慧采集终端</div>
          </div>

          <div
            :class="{ c1s: a2 == false, c1: a2 == true }"
            @click="showNavType('a2')"
          >
            - 智能集成电力电容补偿装置
          </div>
          <div v-show="a2">
            <div class="c2">F系列</div>
            <div class="c2">H系列</div>
          </div>

          <div
            :class="{ c1s: a3 == false, c1: a3 == true }"
            @click="showNavType('a3')"
          >
            - 智能抑谐式集成电力电容补偿装置
          </div>
          <div v-show="a3">
            <div class="c2">单电容电抗</div>
            <div class="c2">双电容电容</div>
            <div class="c2">智能动态抑谐式电容器</div>
          </div>

          <div
            :class="{ c1s: a4 == false, c1: a4 == true }"
            @click="showNavType('a4')"
          >
            - 电能质量治理装置
          </div>
          <div v-show="a4">
            <div class="c2">有源电力滤波器</div>
            <div class="c2">静止无功发生器</div>
            <div class="c2">电能质量成套装置</div>
          </div>

          <div
            :class="{ c1s: a5 == false, c1: a5 == true }"
            @click="showNavType('a5')"
          >
            - 综合测控装置
          </div>
          <div v-show="a5">
            <div class="c2">低压无功综合测控仪/T9系列</div>
          </div>

          <div
            :class="{ c1s: a6 == false, c1: a6 == true }"
            @click="showNavType('a6')"
          >
            - 传统式无功补偿器件
          </div>
          <div v-show="a6">
            <div class="c2">自愈式低压电力电容器</div>
            <div class="c2">抑谐式无功补偿组件</div>
            <div class="c2">智能复合开关</div>
            <div class="c2">智能复合开关</div>
            <div class="c2">传统式无功补偿控制器</div>
          </div>

          <div
            :class="{ c1s: a7 == false, c1: a7 == true }"
            @click="showNavType('a7')"
          >
            - 物联终端
          </div>
          <div v-show="a7">
            <div class="c2">网关采集器</div>
            <div class="c2">能效采集终端</div>
            <div class="c2">输入输出控制模块</div>
            <div class="c2">传感器</div>
          </div>
        </div>

        <div class="g-proList-r">
          <div class="g-proList-r-title">智能抑谐式集成电力电容补偿装置</div>

          <div class="g-proList-r-box">
            <div
              class="proList-box-item"
              v-for="(val, index) in Factorys"
              :key="index"
            >
              <div class="g-proList-r-img public-img g-img-hover">
                <img
                  :src="val.image"
                  :alt="val.title"
                  style="width: 250px; height: 190px"
                />
              </div>

              <div class="g-proList-r-txt">
                <div class="g-font18">{{ val.title }}</div>
                <div class="g-proList-r-p">
                  <p>额定电流: 10A-1250A</p>
                  <p>额定电压: AC230/240/400/660/690</p>
                  <p>极数: 2P/3P/4P</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav-footer />
  </div>
</template>
    <script>
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "product",
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      a: "",
      product: {}, //商品信息
      Factorys: [],
      a1: true,
      a2: false,
      a3: false,
      a4: false,
      a5: false,
      a6: false,
      a7: false,
    };
  },

  mounted() {
    this.getFactorys();
    this.a1 = false;
    this.a2 = false;
    this.a3 = false;
    this.a4 = false;
    this.a5 = false;
    this.a6 = false;
    this.a7 = false;
    if ("a1" == this.$route.params.val) {
      this.a1 = true;
    } else if ("a2" == this.$route.params.val) {
      this.a2 = true;
    } else if ("a3" == this.$route.params.val) {
      this.a3 = true;
    } else if ("a4" == this.$route.params.val) {
      this.a4 = true;
    } else if ("a5" == this.$route.params.val) {
      this.a5 = true;
    } else if ("a6" == this.$route.params.val) {
      this.a6 = true;
    } else if ("a7" == this.$route.params.val) {
      this.a7 = true;
    }
  },
  methods: {
    showNavType(val) {
      this.a1 = false;
      this.a2 = false;
      this.a3 = false;
      this.a4 = false;
      this.a5 = false;
      this.a6 = false;
      this.a7 = false;

      if (val == "a1") {
        this.a1 = true;
      } else if (val == "a2") {
        this.a2 = true;
      } else if (val == "a3") {
        this.a3 = true;
      } else if (val == "a4") {
        this.a4 = true;
      } else if (val == "a5") {
        this.a5 = true;
      } else if (val == "a6") {
        this.a6 = true;
      } else if (val == "a7") {
        this.a7 = true;
      }
    },
    // 获取商品信息
    getFactorys() {
      let self = this;
      this.$api.mall
        .getProductList({
          limit: 12,
          type: "33",
          page: 1,
        })
        .then((ress) => {
          let res = ress.data.data.data;
          self.Factorys = res;
          this.total = ress.data.data.total;
        });
    },
  },
};
</script>
    <style lang="scss">
.product_type_lists {
  width: 100%;
  overflow: hidden;
  background: #f5f5f5;

  .product_type_centers {
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 75px;
    margin: 0px auto;
    .g-proList-box {
      width: 1213px;
      overflow: hidden;
      margin: 20px auto;
      .g-proList-r {
        border-left: 1px solid #e2e2e2;
        width: calc(70.72% - 0.6rem);
        padding-left: 10px;
        box-sizing: border-box;
        float: right;
        overflow: hidden;
        .g-proList-r-title {
          border: 1px solid #eaeaea;
          box-sizing: border-box;
          width: 99.9%;
          overflow: hidden;
          background: #fff;
          padding: 24px 20px;
          font-size: 16px;
          margin-bottom: 20px;
        }

        .g-proList-r-box {
          width: 100%;
          overflow: hidden;
          flex-wrap: wrap;
          margin-bottom: 0.3rem;
          img:hover {
            transition: all 0.3s;
            transform: scale(1.05);
            -webkit-transform: scale(1.05);
          }
          .proList-box-item:hover {
            box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
            border-left: 2px solid #1d8d3b;
          }
          .proList-box-item {
            width: 32.3%;
            //transition: all 0.3s;
            cursor: pointer;
            box-sizing: border-box;
            padding: 10px;
            overflow: hidden;
            float: left;
            margin-left: 1%;
            background: #fff;
            margin-top: 12px;
            .g-font18 {
              color: #333;
              width: 100%;
              font-weight: bold;
              line-height: 1.5;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .g-order_list_box {
        width: 29.28%;
        float: left;
        padding: 0.8rem 0.2rem 0 0;
        position: sticky;
        line-height: 2.8;
        top: 0;
        .c1 {
          font-size: 16px;
          font-weight: bold;
          color: #333;

          cursor: pointer;
        }
        .c1s {
          font-size: 16px;
          color: #333;

          cursor: pointer;
        }
        .c2:hover {
          background: #fff;
          color: #1d8d3b;
          border-left: 2px solid #1d8d3b;
        }
        .c2 {
          box-sizing: border-box;
          cursor: pointer;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          color: #333;
          border-left: 3px solid transparent;
          padding-left: 28px;
        }
      }
    }
  }
}
</style>