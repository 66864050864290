<template>
  <!-- 底部导航 -->
  <div class="footer">
    <div class="w980">
      <div class="ewm fl">
        <img
          src="../../public/imgs/white_logo.png"
          width="190"
          height="48"
          style="margin-bottom: 12px"
          alt=""
        />
        <img
          src="https://omo-oss-image.thefastimg.com/portal-saas/new2022051809265841846/cms/image/9e2bf992-ef60-4949-968e-9d160adf52fc.jpg_186xaf.jpg"
          width="120"
          height="120"
          style="margin-bottom: 12px"
          alt=""
        />
        <p>安耐杰微信公众平台</p>
      </div>
      <div class="footer-main fr">
        <div class="menu">
          <li><a class="tit">首页</a></li>
          <li><a class="tit" @click="goPages('/yunplat')">安耐杰云平台</a></li>
          <li><a class="tit" @click="goPages('/cm_city')">解决方案</a></li>
          <li><a class="tit" @click="goPages('/prod_type33')">终端产品</a></li>
          <li><a class="tit" @click="goPages('/buildings')">项目案例</a></li>
          <li><a class="tit" @click="goPages('/Companynew')">新闻中心</a></li>
          <li><a class="tit" @click="goPages('/about')">关于我们</a></li>
          <li><a class="tit last" @click="goPages('/contact')">联系我们</a></li>
        </div>
        <p>
          地
          址：浙江省台州市椒江区下陈街道洪三路聚星科创园C区9幢&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：400
          1578 100，0576-8859 6888
        </p>
        <p>
          传真：0576-8859 6888&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;邮箱：<a
            href="#"
            >anjpower@163.com</a
          >
        </p>
        <p>Copyright © 2023 台州安耐杰电力设备有限公司 All Rights Reserved</p>
        <p class="fl">
          技术支持：<a
            target="_blank"
            title="台州安耐杰电力设备有限公司"
            href="#"
            >台州安耐杰电力设备有限公司</a
          >
        </p>
        <p style="width: 100%; overflow: hidden; display: block">
          <a target="_blank" title="浙ICP备2023027459号" href="#"
            >浙ICP备2023027459号</a
          >
        </p>
        <div class="clear"></div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-footer",
  methods: {
    goPages(val) {
      let self = this;
      this.$router.push(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  overflow: hidden;
  background: #091b27;
  a {
    text-decoration: none;
    color: #cbd2de;
  }
  .fr {
    float: right;
    display: inline;
  }
  .footer-main {
    width: 915px;
    padding-top: 24px;
    line-height: 28px;
    color: #cbd2de;
    text-transform: uppercase;

    .menu {
      height: 50px;
      border-bottom: 1px solid #fff;
      margin-bottom: 19px;
      p {
        color: #cbd2de;
      }
      a {
        cursor: pointer;
        color: #cbd2de;
        transition: color 500ms ease-in 0ms;
        display: inline-block;
        padding: 0 19px;
        text-decoration: none;
        font-size: 14px;
        line-height: 50px;
        color: #e7e7e7;
      }
      li {
        float: left;
        list-style: none;
      }
    }
  }
  .w980 {
    position: relative;
    width: 1200px;
    overflow: hidden;
    margin: 0 auto;
    .ewm {
      width: 265px;
      overflow: hidden;
      padding-top: 12px;
      padding-bottom: 12px;
      background: #1d8d3b;
      line-height: 24px;
      color: #fff;
      text-align: center;
    }
  }
}
</style>